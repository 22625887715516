import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import { get, post, put } from "helpers/api_helper";
import * as azureSdk from "microsoft-cognitiveservices-speech-sdk"

const TalkComponent = () => {
  const navigate = useNavigate()
  const { questionnaireUUID } = useParams();

  const [ apiData, setApiData] = useState({})
  const [ welcomeText, setWelcomeText ] = useState('')

  const key = process.env.REACT_APP_AZURE_KEY;
  const region = process.env.REACT_APP_AZURE_REGION;
  const speechConfig = azureSdk.SpeechConfig.fromSubscription(key, region);

  const API = {
    fetchContent: async () => {
        // setContentDetailLoading(true)
        const result = await get(process.env.REACT_APP_BACKEND_URL + '/v1/admin/questionnaire/get-category/' + questionnaireUUID)
        const resData = result.data
        console.log("🚀 ~ fetchContent: ~ resData TalkComponent:", resData)

        // PREFILE INPUT FORM
        setApiData(resData)
        
        // setContentDetailLoading(false)
    }
  }

  useEffect(() => {
    API.fetchContent()
  }, [])

  useEffect(() => {
    console.log('apiData.lang_code', apiData)
    switch (apiData.lang_code) {
      case "en-US":
        speechConfig.speechSynthesisVoiceName = 'en-US-AvaNeural'
        setWelcomeText('"This interview is conducted in English. Please respond in English only. If you are ready to begin the interview, please type \'Start.\'"')
        break;
      case "ja-JP":
        speechConfig.speechSynthesisVoiceName = 'ja-JP-NanamiNeural'
        setWelcomeText('このインタビューは日本語で行われます。日本語でのみお答えください。 インタビューを始める準備ができたら、「開始」と入力してください。')
        break;
      case "zh-CN":
        speechConfig.speechSynthesisVoiceName = 'zh-CN-XiaoxiaoNeural'
        setWelcomeText('本次面试将以中文进行。请仅用中文回答。 如果您已准备好开始面试，请输入“开始”。')
        break;
      case "zh-TW":
        speechConfig.speechSynthesisVoiceName = 'zh-TW-HsiaoChenNeural'
        setWelcomeText('"本次面試將以中文進行，請僅用中文回答。 如果您已準備好開始，請輸入“開始”。"')
        break;
      case "vi-VN":
        speechConfig.speechSynthesisVoiceName = 'vi-VN-HoaiMyNeural'
        setWelcomeText('Cuộc phỏng vấn này được tiến hành bằng tiếng Việt. Vui lòng trả lời bằng tiếng Việt. Nếu bạn đã sẵn sàng để bắt đầu phỏng vấn, vui lòng nhập \'Bắt đầu\'.')
        break;
      case "th-TH":
        speechConfig.speechSynthesisVoiceName = 'th-TH-PremwadeeNeural'
        setWelcomeText('บทสัมภาษณ์นี้จัดทำขึ้นเพื่อสัมภาษณ์ในภาษาไทย กรุณาตอบเป็นภาษาไทยเท่านั้น หากคุณพร้อมในการสัมภาษณ์แล้ว ให้พิมพ์คำว่า \'เริ่ม\'')
        break;
    
      default:
        speechConfig.speechSynthesisVoiceName = 'en-US-AvaNeural'
        setWelcomeText('"This interview is conducted in English. Please respond in English only. If you are ready to begin the interview, please type \'Start.\'"')
        break;
    }
  }, [apiData])

  useEffect(() => {
    if (questionnaireUUID) {
      const v7 = () => {
        return 'tttttttt-tttt-7xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.trunc(Math.random() * 16);
            const v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        }).replace(/^[t]{8}-[t]{4}/, function () {
            const unixtimestamp = Date.now().toString(16).padStart(12, '0');
            return unixtimestamp.slice(0, 8) + '-' + unixtimestamp.slice(8);
        });
      }

      const chatId = v7()
      const queryString = window.location.search;
      console.log(queryString);
      const urlParams = new URLSearchParams(queryString);

      Talk.ready.then(function () {
          const me = new Talk.User({
              id: chatId,
              name: chatId,
              role: "default",
              photoUrl: `${process.env.REACT_APP_FE_URL}/img/user_icon.png`,
          });

          const talkSession = new Talk.Session({
              appId: "t7KXPj1P", // replace with your TalkJS app ID
              me: me,
          });

          const bot = new Talk.User({
              id: `ai-shopping-bot-${chatId}`,
              name: "Delphi",
              role: "default",
              photoUrl: `${process.env.REACT_APP_FE_URL}/img/bot_img.png`,
              welcomeMessage: welcomeText,
          });

          var conversation = talkSession.getOrCreateConversation(
              chatId
          );
          console.log("🚀 ~ chatId:", chatId)


          conversation.setParticipant(me);
          conversation.setParticipant(bot);
          conversation.setAttributes({
              custom: {
                  questionnaireId: questionnaireUUID,
                  phoneNumber: localStorage.getItem('phoneNumber'),
                  aiPrompt: localStorage.getItem('aiPrompt')
              }
          });

          const inbox = talkSession.createChatbox();

          inbox.onCustomMessageAction('textToSpeech', (event) => {
            console.log('Action has been triggered!');
            console.log('event', event)

            let synthesizer = new azureSdk.SpeechSynthesizer(speechConfig)
            synthesizer.speakTextAsync(
              event.message.body,
              function (result) {
                if (result.reason === azureSdk.ResultReason.SynthesizingAudioCompleted) {
                  console.log("synthesis finished.");
                } else {
                  console.error(`Speech synthesis canceled: ${result.errorDetails} \nDid you set the speech resource key and region values?`);
                }
                synthesizer.close();
                synthesizer = null;
              },
              function (err) {
                console.trace(`Error: ${err}`);
                synthesizer.close();
                synthesizer = null
              }
            );
          });

          inbox.select(conversation);
          inbox.mount(document.getElementById("talkjs-container"));
      });
    }

    return () => {
      // Cleanup script when the component is unmounted
      const script = document.querySelector('script[src="https://cdn.talkjs.com/talk.js"]');
      if (script) {
        document.head.removeChild(script);
      }
    };
  }, [questionnaireUUID, apiData, welcomeText]);

  useEffect(()=> {
    const fetchContent = async () => {
    const result = await post(process.env.REACT_APP_BACKEND_URL + '/public/check-questionaire', {
      phone_number: localStorage.getItem('phoneNumber'),
      uuid_link: questionnaireUUID
    })
      console.log('result', result)
      if (result.status === '200') {
        if (result.data.length) {
          alert('This phone number has already taken the survey. Retaking is not available at this time.')
          navigate('/pdpa/chat/'+ questionnaireUUID)
        }
      }
    };

    fetchContent();
  },[])
  document.title = "Webchat"
  return <div id="talkjs-container" style={{ width: '100%', height: '100vh' }} />;
};

export default TalkComponent;
