import PropTypes from "prop-types"
import React, { useEffect, useState, useMemo, useCallback } from "react"
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table,
    Badge,
    Label,
} from "reactstrap"

import { Link, useNavigate, useParams } from "react-router-dom"

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb2';
import TableContainer from '../../components/Common/TableContainer2';
import { withTranslation } from "react-i18next";

import { get, put, del } from "helpers/api_helper";
import { checkScope } from "helpers/utility_helper";
import "../../components/Common/datatables.scss"
import Spinners from "components/Common/Spinner";
import moment from "moment";
// import ConsentModal from "components/Common/ConsentModal";
import { Editor } from '@tinymce/tinymce-react';
import { AgreementMessage, ActivityRules, PrivacyPolicy } from "common/data/consent";
import { updateQuestionaireConsent, updateQuestionaireConsentReset } from "../../store/actions"
//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import useQuestionnaireStore from "../../zustand/store/QuestionnaireModule";
import { ExportJsonToExcel } from "components/ExportJsonToExcel";
import { ExportJsonToExcelAPI } from "components/ExportJsonToExcelAPI";
import UploadFileAPI from "components/UploadFileAPI";

const RowStatus = ({ status }) => {
    switch (status) {
        // case "inactive":
        //     return <Badge className="bg-warning"> Inactive </Badge>;
    
        // case "deleted":
        //     return <Badge className="bg-danger"> Deleted </Badge>;
    
        case true:
            return <Badge className="bg-success"> Active </Badge>;
    
        default:
            return <Badge className="bg-success"> {status} </Badge>;
    }
};

const QuestionType = ({ question_type }) => {
    switch (question_type) {
        case "multi":
            return <span>Multiple Choice</span>;
    
        case "single":
            return <span>Single Answer</span>;
    
        case "openend":
            return <span>Open-end</span>;
        
        case "image":
            return <span>Image Upload</span>;
    
        default:
            return <span></span>;
    }
};

const QuestionaireList = props => {

    // CENTER STATE
    // const questionnaireStore = useQuestionnaireStore(); // STATE
    const { setStep1, resetQuestionnaire } = useQuestionnaireStore(); // ACTION

    const { categoryName } = useParams();

    const navigate = useNavigate();

    const deleteRow = useCallback(
        (id) => () => {
            if (confirm("Are you sure?") == true) {
                console.log("ID: ", id)

                const delContent = async () => {
                    const result = await del(process.env.REACT_APP_BACKEND_URL + '/v1/admin/questionnaire/delete/' + id)
                    console.log(result)
                    if(result.status === 201) {
                        setContentListLoading(true)
                        window.location.reload()
                    }
                };
            
                delContent();
            }
        },
        []
    )

    const columns = useMemo(
        () => [
            // {
            //     id: "colcheckbox",
            //     header: () => {
            //         return (
            //             <div style={{ display: "flex", justifyContent: "center" }}>
            //                 <Input type={`checkbox`} />
            //             </div>
            //     )},
            //     enableColumnFilter: false,
            //     enableSorting: false,
            //     cell: (cellProps) => {
            //         return (
            //             <>
            //                 <div id={cellProps.row.id} style={{ display: "flex", justifyContent: "center" }}>
            //                     <Input type={`checkbox`} value={cellProps.row.original.user_id} />
            //                 </div>
            //             </>
            //         )
            //     }
            // },
            {
                header: '#',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    return (
                        <>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                { (parseInt(cellProps.row.id) + 1) }
                            </div>
                        </>
                    )
                }
            },
            {
                header: 'Category Name',
                accessorKey: 'category_name',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Survey Name',
                accessorKey: 'survey_name',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Create Date',
                accessorKey: 'create_date',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    return (
                        moment(cellProps.row.original.create_date).format("YYYY-MM-DD HH:mm")
                    )
                }
            },
            {
                header: 'Create By',
                accessorKey: 'create_by_name',
                enableColumnFilter: false,
                enableSorting: false,
            },
            {
                header: 'Questionnaire Status',
                accessorKey: 'active',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => <RowStatus status={cellProps.row.original.active} />
            },
            {
                header: 'Tools',
                accessorKey: 'user_id',
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps) => {
                    let _questionaireId = cellProps.row.original.id;
                    let _cateId = cellProps.row.original.category_id;
                    return (
                        <>
                            {
                                checkScope('questionnaire:create') && (
                                    <ExportJsonToExcelAPI
                                        mapHeaderColumn={
                                            {
                                                chat_uuid: 'User ID',
                                                phone_number: 'Phone Number',
                                                questionnaire_id: 'Question ID',
                                                question_number: 'Q.No',
                                                questionType: 'Question type',
                                                questionText: 'Question text',
                                                answer_text: 'Answer',
                                                message_type: 'End Message',
                                                result: 'Result',
                                            }
                                        }
                                        apiPath={`/v1/admin/questionnaire/export/` + cellProps.row.original.category_id} 
                                        fileName={'questionnaire'}
                                        dateColumns={['create_date']}
                                    />
                                )
                            }
                            &nbsp;
                            {checkScope('questionnaire:create') && <Button className={`btn btn-primary`} title={`Incentive Condition and Consent Message`} data-toggle="modal" onClick={() => {openModal(cellProps.row.original.category_id); setModalIsOpen(true);}}><i className={`bx bx-check-square`}></i></Button>}
                            &nbsp;
                            {checkScope('questionnaire:update') && <Link className={`btn btn-warning`} title={`Edit`} to={`/questionnaire/category/edit/${cellProps.row.original.category_id}`}><i className={`bx bx-edit-alt`}></i></Link>}
                            &nbsp;
                            {checkScope('questionnaire:delete') && <Link className={`btn btn-danger`} title={`Delete`} onClick={deleteRow(_questionaireId)}><i className={`bx bx-trash`}></i></Link>}
                        </>
                    )
                }
            },
        ],
        []
    );

    const [contentList, setContentList] = useState([])
    const [contentListLoading, setContentListLoading] = useState(false)

    useEffect(() => {
        setContentListLoading(true)
        const fetchContentList = async () => {
            const result = await get(process.env.REACT_APP_BACKEND_URL + '/v1/admin/questionnaire/list/' + categoryName)
            console.log('result', result)
            setContentList(result.data);
        };
    
        fetchContentList();

        resetQuestionnaire(); // CLEAR STATE ก่อนเข้า หน้า FORM
    }, []);

    useEffect(() => {
        setContentListLoading(false)
    }, [contentList])

    // ========== Modal ========== //
    const dispatch = useDispatch();
    const QuestionaireQuestionProperties = createSelector(
        (state) => state.questionaireConsent,
        (questionaireConsent) => ({
            questionData: questionaireConsent.questionData,
            updateError: questionaireConsent.updateError,
            updateSuccess: questionaireConsent.updateSuccess,
            loading: questionaireConsent.loading,
        })
    );

    const {
        questionData, updateError, updateSuccess, loading
    } = useSelector(QuestionaireQuestionProperties);

    const [ modalIsOpen, setModalIsOpen] = useState(false);
    const [ consentCategoryId, setConsentCategoryId ] = useState()
    const [ bucketId, setBucketId ] = useState()
    const [ consentLoading, setConsentLoading ] = useState(false)
    const [ box1, setBox1 ] = useState()
    const [ box2, setBox2 ] = useState()
    const [ box3, setBox3 ] = useState()
    const [ imageBanner, setImageBanner ] = useState(null)
    const [ title, setTitle ] = useState('')
    const [ isSubmit, setIsSubmit ] = useState(false)

    const [ box1Init, setBox1Init ] = useState()
    const [ box2Init, setBox2Init ] = useState()
    const [ box3Init, setBox3Init ] = useState()
    const handleEditorChange = (box, e) => {
        switch (box) {
            case 'box1':
                setBox1(e)
                break;

            case 'box2':
                setBox2(e)
                break;

            case 'box3':
                setBox3(e)
                break;
        
            default:
                break;
        }
    }

    const handleSaveConsent = async () => {
        setIsSubmit(true)
        if (title) {
            const consentContent = {
                title: title,
                imageBanner: imageBanner,
                bucketId: bucketId,
                box1,
                box2,
                box3
            }
            dispatch(updateQuestionaireConsent(consentContent));
        }
    }

    useEffect(() => {
        if(updateSuccess) {
            dispatch(updateQuestionaireConsentReset());
            setModalIsOpen(false)
        }
        else {

        }
    }, [questionData])

    const loadSingleQuestionaire = (categoryId) => {
        setConsentLoading(true)
        const fetchContent = async () => {
            const result = await get(process.env.REACT_APP_BACKEND_URL + '/v1/admin/questionnaire/edit/' + categoryId)
            console.log('result', result)
            setBucketId(result.data.id)
            setTitle(result.data.title ? result.data.title : null)
            setImageBanner(result.data.image_banner ? result.data.image_banner : null)
            setBox1Init(result.data.box1 ? result.data.box1 : AgreementMessage)
            setBox2Init(result.data.box2 ? result.data.box2 : ActivityRules)
            setBox3Init(result.data.box3 ? result.data.box3 : PrivacyPolicy)

            setBox1(result.data.box1 ? result.data.box1 : AgreementMessage)
            setBox2(result.data.box2 ? result.data.box2 : ActivityRules)
            setBox3(result.data.box3 ? result.data.box3 : PrivacyPolicy)

            setConsentLoading(false)
        };
    
        fetchContent();
    }

    function openModal(categoryId) {
        setConsentCategoryId(categoryId)
        loadSingleQuestionaire(categoryId)
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    useEffect(() => {
        console.log('modalIsOpen', modalIsOpen)
    }, [modalIsOpen])
    // ========== Modal ========== //

    //meta title
    document.title = "Questionnaire | " + process.env.REACT_APP_CMS_TITLE;

    return (
        <>
            <Modal
                size="xl"
                scrollable={true}
                isOpen={modalIsOpen}
            >
                {consentLoading ? <Spinners setLoading={setConsentLoading} />
                : <>
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="consentModalLabel">
                            Incentive Condition and Consent Message ({consentCategoryId})
                        </h5>
                        <button
                            type="button"
                            onClick={() => {
                                setModalIsOpen(false);
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-5">
                            <UploadFileAPI 
                                upload="Image"
                                typeUpload="ImageMap"
                                prefixName="banner-chat"
                                label="Upload Banner"
                                widthSize={940}
                                heightSize={550}
                                description="description"
                                value={imageBanner}
                                onChange={([ imageURL = '', imageSize = '']) => {
                                    console.log("🚀 ~ QuestionaireList ~ imageURL:", imageURL)
                                    setImageBanner(imageURL)
                                }}
                            />
                        </div>
                        <div className="mb-5">
                            <Label className="h5">
                            Add additional event details
                            </Label>
                            <Input
                                name="username"
                                className="form-control"
                                // placeholder="Enter Add additional event details"
                                type="text"
                                onChange={(e)=> setTitle(e.target.value)}
                                value={title || ""}
                                invalid={isSubmit && !title ? true : false}
                            />
                            {
                                isSubmit && !title && (
                                    <div type="invalid" className="invalid-feedback d-block">Require Field Add additional event details</div>
                                )
                            }
                        </div>
                        <div className="mb-5">
                            <Label className="h5">
                                Agreement Message
                            </Label>
                            <div className="mb-3">
                                <cite title="Ask respondents to agree with the privacy policy">Ask respondents to agree with the privacy policy</cite>
                            </div>
                            <Editor
                                id="box1"
                                initialValue={box1Init}
                                value={box1}
                                onEditorChange={(newValue, editor) => handleEditorChange('box1', newValue)}
                                init={{
                                    height: 350
                                }}
                            />
                        </div>
                        <div className="mb-5">
                            <Label className="h5">
                                Activity Rules
                            </Label>
                            <div className="mb-3">
                                <cite title="Tell respondents the contents of this survey">Tell respondents the contents of this survey</cite>
                            </div>
                            <Editor
                                id="box2"
                                initialValue={box2Init}
                                value={box2}
                                onEditorChange={(newValue, editor) => handleEditorChange('box2', newValue)}
                                init={{
                                    height: 350
                                }}
                            />
                        </div>
                        <div>
                            <Label className="h5">
                                Privacy Policy
                            </Label>
                            <Editor
                                id="box3"
                                initialValue={box3Init}
                                value={box3}
                                onEditorChange={(newValue, editor) => handleEditorChange('box3', newValue)}
                                init={{
                                    height: 350
                                }}
                            />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            onClick={() => {
                                setModalIsOpen(false);
                            }}
                            className="btn btn-secondary "
                            data-dismiss="modal"
                        >
                        Close
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary "
                            onClick={handleSaveConsent}
                        >
                            Save changes
                        </button>
                    </div>
                </>
                }
            </Modal>
            <div className="page-content">
                <div className="container-fluid">
                    {/* <Breadcrumbs title="Questionnaire" breadcrumbItem="List" /> */}
                    <Breadcrumbs 
                        title={props.t("Questionnaire")} 
                        breadcrumbItems={[
                            {title: "Questionnaire", link: "questionnaire"}, 
                            // {title: "Category", link: "category"}, 
                            {title: "List - " + categoryName, link: "list"},
                            // {title: categoryName, link: categoryName},
                        ]}
                    />

                    {
                        contentListLoading ? <Spinners setLoading={setContentListLoading} />
                        : 
                            <TableContainer
                                isAddButton={false}
                                addRowLink={`/category`}
                                isExportButton={true}
                                exportLink={`/export`}
                                columns={columns}
                                data={contentList || []}
                                isGlobalFilter={true}
                                isPagination={true}
                                SearchPlaceholder="Search"
                                pagination="pagination"
                                paginationWrapper='dataTables_paginate paging_simple_numbers'
                                tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                                rootMenu="systemsettings"
                                subMenu="usersetting"
                            />
                    }
                </div>
            </div>
        </>
    );
}

QuestionaireList.propTypes = {
    t: PropTypes.any,
}

export default withTranslation()(QuestionaireList)